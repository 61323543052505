<template>
  <section>
    <h3 class="mb-6 font-bold">Driver's Licence</h3>
    <p class="block">Do you hold a current Drivers Licence?</p>
    <div class="mb-4 mt-2 flex items-center">
      <div class="flex-1 space-x-4">
        <input
          id="has-au-licence"
          v-model="hasLicence"
          type="radio"
          name="hasAuLicence'"
          :value="true"
        />
        <label for="has-au-licence">Yes</label>
      </div>
      <div class="flex-1 space-x-4">
        <input
          id="no-au-licence"
          v-model="hasLicence"
          name="hasAuLicence'"
          type="radio"
          :value="false"
        />
        <label for="no-au-licence">No</label>
      </div>
    </div>

    <div v-if="hasLicence">
      <div v-if="canBeInternational" class="mb-4">
        <BaseSelector
          :options="[
            { id: 'Australian Licence', name: 'Australian Licence' },
            { id: 'International Licence', name: 'International Licence' },
          ]"
          select-element-style="block border-gray-300 focus:ring-blue-500 focus:border-blue-500 focus:outline-none py-3 rounded-md w-full px-2"
          placeholder="Type"
          :disabled="isLoading"
          @option-changed="selectLicenceType"
        />
        <span v-if="errorMessage.licenceType" class="error m-1 !my-0 block">
          {{ errorMessage.licenceType }}
        </span>
      </div>

      <div
        v-if="licenceType === 'Australian Licence'"
        class="mb-4 mt-6 flex-1 space-y-4"
      >
        <BaseInput
          v-model:inputValue="firstName"
          label="First Name (as appears on licence)"
          identifier="licence-first-name"
          :is-valid="errorMessage.firstName === ''"
          :disabled="isLoading"
        >
        </BaseInput>

        <BaseInput
          v-model:inputValue="lastName"
          label="Last Name (as appears on licence)"
          identifier="licence-last-name"
          :is-valid="errorMessage.lastName === ''"
          :disabled="isLoading"
        >
          <span v-if="errorMessage.lastName" class="error m-1 !my-0 block">
            {{ errorMessage.lastName }}
          </span>
        </BaseInput>

        <div class="flex items-center gap-x-2">
          <BaseInput
            v-model:inputValue="licenceNumber"
            class="flex-grow"
            label="Licence No"
            identifier="licence-no"
            :is-valid="errorMessage.licenceNumber === ''"
            :disabled="isLoading"
          >
            <span
              v-if="errorMessage.licenceNumber"
              class="error m-1 !my-0 block"
            >
              {{ errorMessage.licenceNumber }}
            </span>
          </BaseInput>
          <div
            class="relative inline-block w-fit"
            aria-labelledby="au-licence-number-tooltip"
          >
            <InformationCircleIcon
              class="focus-outline-0 h-6 w-6 stroke-gray-600 focus:outline-none"
              @click="showLicenceNumberTooltip = !showLicenceNumberTooltip"
            />
            <BaseModal
              v-if="showLicenceNumberTooltip"
              title="Australian Drivers Licence Example"
              @close="showLicenceNumberTooltip = false"
            >
              <img
                class="h-full w-full"
                src="@/assets/australian-drivers-licence-number-example.png"
                alt="au-drivers-licence-number-example"
              />
            </BaseModal>
          </div>
        </div>

        <div class="flex items-center gap-x-2">
          <BaseInput
            v-model:inputValue="licenceCardNumber"
            class="flex-grow"
            label="Licence Card No"
            identifier="licence-card-no"
            :is-valid="errorMessage.licenceCardNumber === ''"
            :disabled="isLoading"
          >
            <span
              v-if="errorMessage.licenceCardNumber"
              class="error m-1 !my-0 block"
            >
              {{ errorMessage.licenceCardNumber }}
            </span>
          </BaseInput>
          <div
            class="relative inline-block w-fit"
            aria-labelledby="au-licence-card-number-tooltip"
          >
            <InformationCircleIcon
              class="focus-outline-0 h-6 w-6 stroke-gray-600 focus:outline-none"
              @click="
                showLicenceCardNumberTooltip = !showLicenceCardNumberTooltip
              "
            />
            <BaseModal
              v-if="showLicenceCardNumberTooltip"
              title="Australian Drivers Licence Example"
              @close="showLicenceCardNumberTooltip = false"
            >
              <div>
                <p class="mb-2 p-2 text-start">
                  Your card number can be found on the back of your physical
                  licence either: 1. On the top right-hand corner 2. or Under
                  your year of birth (if your licence was issued prior to
                  November 2022)
                </p>
                <img
                  class="h-full w-full"
                  src="@/assets/australian-drivers-licence-card-number-example.png"
                  alt="au-drivers-licence-card-number-example"
                />
              </div>
            </BaseModal>
          </div>
        </div>

        <BaseSelector
          :options="states"
          placeholder="State (Where licence obtained)"
          select-element-style="block border-gray-300 focus:ring-blue-500 focus:border-blue-500 focus:outline-none py-3 rounded-md w-full px-2"
          :disabled="isLoading"
          @option-changed="selectState"
        />
        <span v-if="errorMessage.state" class="error m-1 !my-0 block">
          {{ errorMessage.state }}
        </span>

        <div :id="accreditation.id + 'dateOfBirth'">
          <VueDatePicker
            v-model="dateOfBirth"
            :enable-time-picker="false"
            placeholder="Date of birth"
            format="dd-MM-yyyy"
            :max-date="new Date()"
            auto-apply
          />
          <span v-if="errorMessage.dateOfBirth" class="error m-1 !my-0 block">
            {{ errorMessage.dateOfBirth }}
          </span>
        </div>

        <div class="flex items-baseline gap-x-2">
          <input
            id="au-licence-consent"
            v-model="canPerformLicenceCheck"
            type="checkbox"
            class="outline-0"
            :value="true"
          />
          <label for="au-licence-consent">
            I consent to Verified performing a Licence check with DVS
          </label>
        </div>
        <span
          v-if="errorMessage.canPerformLicenceCheck"
          class="error m-1 !my-0 block"
        >
          {{ errorMessage.canPerformLicenceCheck }}
        </span>
      </div>
    </div>
    <AlertBanner
      v-else-if="hasLicence === false"
      class="mb-6"
      component-style="warning"
      content="This site requires you to have a driver's licence."
    />
  </section>
</template>

<script setup lang="ts">
  import { onMounted, PropType, reactive, ref, watch, watchEffect } from 'vue'
  import BaseInput from '@/components/BaseInput.vue'
  import VueDatePicker from '@vuepic/vue-datepicker'
  import '@vuepic/vue-datepicker/dist/main.css'
  import dayjs from 'dayjs'
  import { InformationCircleIcon } from '@heroicons/vue/24/outline'
  import {
    AccreditationCheckType,
    AccreditationType,
  } from '@/services/api/accreditation'
  import { useUserStore } from '@/storage/user'
  import ReportableException from '@/exceptions/ReportableException'
  import AlertBanner from '@/components/AlertBanner.vue'
  import { SelectOption } from '@/config'
  import BaseSelector from '@/components/BaseSelector.vue'
  import BaseModal from '@/components/BaseModal.vue'
  import { getSiteEntryCheck } from '@/components/use/siteEntryConfirmation'
  import { SiteEntryCheckType } from '@/storage/siteEntryConfirmation'

  const props = defineProps({
    accreditation: {
      required: true,
      type: Object as PropType<AccreditationType>,
    },
    shouldValidate: Boolean,
  })

  const emit = defineEmits([
    'canSave',
    'hasAccreditation',
    'doesntHaveAccreditation',
    'validated',
  ])

  const state = ref<string | undefined>(undefined)
  const dateOfBirth = ref<string | undefined>(undefined)
  const firstName = ref<string | null>(null)
  const lastName = ref<string | undefined>(undefined)
  const licenceNumber = ref<string | undefined>(undefined)
  const licenceCardNumber = ref<string | undefined>(undefined)

  const hasLicence = ref<boolean | null>(null)
  const licenceType = ref<
    'Australian Licence' | 'International Licence' | undefined
  >(undefined)
  const isLoading = ref(false)
  const canPerformLicenceCheck = ref(false)
  const showLicenceNumberTooltip = ref(false)
  const showLicenceCardNumberTooltip = ref(false)

  const states = [
    { id: 'ACT', name: 'ACT' },
    { id: 'NSW', name: 'NSW' },
    { id: 'NT', name: 'NT' },
    { id: 'QLD', name: 'QLD' },
    { id: 'SA', name: 'SA' },
    { id: 'TAS', name: 'TAS' },
    { id: 'VIC', name: 'VIC' },
    { id: 'WA', name: 'WA' },
  ] satisfies SelectOption[]

  const errorMessage = reactive({
    canPerformLicenceCheck: '',
    dateOfBirth: '',
    firstName: '',
    lastName: '',
    licenceCardNumber: '',
    licenceNumber: '',
    licenceType: '',
    state: '',
  })

  const canBeInternational = ref<boolean>(true)
  onMounted(() => {
    if (hasPreviousInternationalLicence()) {
      canBeInternational.value = false
      licenceType.value = 'Australian Licence'
    }
  })

  watch(hasLicence, () => {
    if (hasLicence.value) {
      emit('hasAccreditation', props.accreditation?.id)
      return
    }

    emit('doesntHaveAccreditation', props.accreditation?.id)

    // reset licence type
    if (canBeInternational.value) {
      licenceType.value = undefined
    } else {
      licenceType.value = 'Australian Licence'
    }
  })

  watchEffect(() => {
    if (props.shouldValidate) {
      validate()
    }
  })

  watch(lastName, () => manageErrorMessage(lastName.value, 'lastName'))
  watch(dateOfBirth, () => {
    manageErrorMessage(dateOfBirth.value, 'dateOfBirth')
  })
  watch(state, () => manageErrorMessage(state.value, 'state'))
  watch(licenceNumber, () =>
    manageErrorMessage(licenceNumber.value, 'licenceNumber'),
  )
  watch(licenceCardNumber, () =>
    manageErrorMessage(licenceCardNumber.value, 'licenceCardNumber'),
  )
  watch(canPerformLicenceCheck, () => {
    manageConsent()
  })
  watch(licenceType, () => manageErrorMessage(licenceType.value, 'licenceType'))

  function manageErrorMessage(data: string | undefined, fieldName: string) {
    if (data && data !== '') {
      errorMessage[fieldName] = ''
      return
    }

    errorMessage[fieldName] = 'Field cannot be empty'
  }

  function manageConsent() {
    errorMessage.canPerformLicenceCheck = canPerformLicenceCheck.value
      ? ''
      : 'Must give consent'
  }

  function selectState(value: string) {
    state.value = value
  }

  function selectLicenceType(
    type: 'Australian Licence' | 'International Licence' | undefined,
  ) {
    licenceType.value = type
  }

  function validate(): boolean {
    if (hasLicence.value === false) {
      emit('validated')
      return false
    }

    manageErrorMessage(lastName.value, 'lastName')
    manageErrorMessage(dateOfBirth.value, 'dateOfBirth')
    manageErrorMessage(state.value, 'state')
    manageErrorMessage(licenceNumber.value, 'licenceNumber')
    manageErrorMessage(licenceCardNumber.value, 'licenceCardNumber')
    manageErrorMessage(licenceType.value, 'licenceType')
    manageConsent()

    const isValid =
      Object.values(errorMessage).every((message) => message === '') ||
      licenceType.value === 'International Licence'

    emit('validated')
    emit('canSave', {
      canSave: isValid,
      userAccreditation: isValid ? createUserAccreditationRequestData() : null,
    })

    return isValid
  }

  function createUserAccreditationRequestData() {
    if (props.accreditation?.id === undefined) {
      throw new ReportableException('Missing accreditation data', {
        accreditation: props.accreditation,
      })
    }

    const data = {
      accreditationId: props.accreditation.id,
      accreditationName: props.accreditation.name,
      isInternationalLicence: licenceType.value === 'International Licence',
      userId: useUserStore().id,
    }

    if (licenceType.value === 'Australian Licence') {
      data['consent'] = canPerformLicenceCheck.value
      data['country'] = 'AU'
      data['firstName'] = firstName.value
      data['lastName'] = lastName.value
      data['licenceCardNumber'] = licenceCardNumber.value
      data['licenceNumber'] = licenceNumber.value
      data['state'] = state.value
      data['dateOfBirth'] = dayjs(dateOfBirth.value)
        .format('DD-MM-YYYY')
        .split('-')
        .reverse()
        .join('-')
    }

    return data
  }

  function hasPreviousInternationalLicence(): boolean {
    const siteEntryCheck = getSiteEntryCheck('Accreditation') as
      | SiteEntryCheckType<AccreditationCheckType>
      | undefined

    if (siteEntryCheck === undefined || !siteEntryCheck.checks) {
      return false
    }

    return siteEntryCheck.checks.some((check: AccreditationCheckType) => {
      return (
        check.name === 'Australian Drivers Licence' &&
        check.description === 'International Drivers Licence'
      )
    })
  }
</script>
