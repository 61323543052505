<template>
  <section>
    <h3 class="mb-6 font-bold">Driver's Licence</h3>
    <p class="block">Do you hold a current New Zealand Drivers Licence?</p>
    <div class="mb-4 mt-2 flex items-center">
      <div class="flex-1 space-x-4">
        <input
          id="has-nz-licence"
          v-model="hasLicence"
          name="hasNzLicence"
          type="radio"
          :value="true"
        />
        <label for="has-nz-licence">Yes</label>
      </div>
      <div class="flex-1 space-x-4">
        <input
          id="no-nz-licence"
          v-model="hasLicence"
          name="hasNzLicence"
          type="radio"
          :value="false"
        />
        <label for="no-nz-licence">No</label>
      </div>
    </div>

    <div v-if="hasLicence">
      <div v-if="canBeInternational" class="mb-4">
        <BaseSelector
          :options="[
            { id: 'NZ Licence', name: 'NZ Licence' },
            { id: 'International Licence', name: 'International Licence' },
          ]"
          select-element-style="block border-gray-300 focus:ring-blue-500 focus:border-blue-500 focus:outline-none py-3 rounded-md w-full px-2"
          placeholder="Type"
          :disabled="isLoading"
          @option-changed="selectLicenceType"
        />
        <span v-if="errorMessage.licenceType" class="error m-1 !my-0 block">
          {{ errorMessage.licenceType }}
        </span>
      </div>

      <div
        v-if="licenceType === 'NZ Licence'"
        class="mb-4 mt-6 flex-1 space-y-4"
      >
        <section>
          <BaseInput
            v-model:inputValue="firstName"
            label="First Name (as appears on licence)"
            identifier="licence-first-name"
            :is-valid="errorMessage.firstName === ''"
            :disabled="isLoading"
          >
          </BaseInput>
        </section>

        <section>
          <BaseInput
            v-model:inputValue="lastName"
            label="Last Name (as appears on licence)"
            identifier="licence-last-name"
            :is-valid="errorMessage.lastName === ''"
            :disabled="isLoading"
          >
            <span v-if="errorMessage.lastName" class="error m-1 !my-0 block">
              {{ errorMessage.lastName }}
            </span>
          </BaseInput>
        </section>

        <section class="flex items-center gap-x-2">
          <BaseInput
            v-model:inputValue="licenceNumber"
            class="flex-grow"
            label="Licence No."
            identifier="licence-no"
            :is-valid="errorMessage.licenceNumber === ''"
            :disabled="isLoading"
          >
            <span
              v-if="errorMessage.licenceNumber"
              class="error m-1 !my-0 block"
            >
              {{ errorMessage.licenceNumber }}
            </span>
          </BaseInput>
          <InformationCircleIcon
            class="focus-outline-0 h-6 w-6 stroke-gray-600 focus:outline-none"
            @click="showTooltip = !showTooltip"
          />
          <BaseModal
            v-if="showTooltip"
            title="New Zealand Drivers Licence Example"
            @close="showTooltip = false"
          >
            <img
              class="h-full w-full"
              src="@/assets/new-zealand-drivers-licence-example.png"
              alt="nz-drivers-licence-example"
            />
          </BaseModal>
        </section>

        <section>
          <BaseInput
            v-model:inputValue="licenceVersion"
            :disabled="isLoading"
            :is-valid="errorMessage.licenceVersion === ''"
            :maxlength="3"
            identifier="licence-version"
            label="Version No."
          >
            <span
              v-if="errorMessage.licenceVersion"
              class="error m-1 !my-0 block"
            >
              {{ errorMessage.licenceVersion }}
            </span>
          </BaseInput>
        </section>

        <section :id="accreditation.id + 'dateOfBirth'">
          <VueDatePicker
            v-model="dateOfBirth"
            :enable-time-picker="false"
            placeholder="Date of birth"
            format="dd-MM-yyyy"
            :max-date="new Date()"
            auto-apply
          />
          <span v-if="errorMessage.dateOfBirth" class="error m-1 !my-0 block">
            {{ errorMessage.dateOfBirth }}
          </span>
        </section>

        <div class="flex items-baseline gap-x-2">
          <input
            id="nz-licence-consent"
            v-model="canPerformLicenceCheck"
            type="checkbox"
            class="outline-0"
            :value="true"
            @click="canPerformLicenceCheck = true"
          />
          <label for="nz-licence-consent">
            I consent to Verified performing a Licence check with DVS
          </label>
        </div>
        <span
          v-if="errorMessage.canPerformLicenceCheck"
          class="error m-1 !my-0 block"
        >
          {{ errorMessage.canPerformLicenceCheck }}
        </span>
      </div>
    </div>

    <AlertBanner
      v-else-if="hasLicence === false"
      class="mb-6"
      component-style="warning"
      content="This site requires you to have a driver's licence."
    />
  </section>
</template>

<script setup lang="ts">
  import { onMounted, PropType, reactive, ref, watch, watchEffect } from 'vue'
  import BaseInput from '@/components/BaseInput.vue'
  import dayjs from 'dayjs'
  import { InformationCircleIcon } from '@heroicons/vue/24/outline'
  import {
    AccreditationCheckType,
    AccreditationType,
  } from '@/services/api/accreditation'
  import { useUserStore } from '@/storage/user'
  import ReportableException from '@/exceptions/ReportableException'
  import AlertBanner from '@/components/AlertBanner.vue'
  import BaseSelector from '@/components/BaseSelector.vue'
  import BaseModal from '@/components/BaseModal.vue'
  import { getSiteEntryCheck } from '@/components/use/siteEntryConfirmation'
  import { SiteEntryCheckType } from '@/storage/siteEntryConfirmation'
  import VueDatePicker from '@vuepic/vue-datepicker'

  const props = defineProps({
    accreditation: {
      required: true,
      type: Object as PropType<AccreditationType>,
    },
    shouldValidate: Boolean,
  })

  const emit = defineEmits([
    'canSave',
    'hasAccreditation',
    'doesntHaveAccreditation',
    'validated',
  ])

  const dateOfBirth = ref<string | undefined>(undefined)
  const firstName = ref<string | null>(null)
  const lastName = ref<string | undefined>(undefined)
  const licenceNumber = ref<string | undefined>(undefined)
  const licenceVersion = ref<string | undefined>(undefined)

  const hasLicence = ref<boolean | null>(null)
  const licenceType = ref<'NZ Licence' | 'International Licence' | undefined>(
    undefined,
  )
  const isLoading = ref(false)
  const canPerformLicenceCheck = ref(false)
  const showTooltip = ref(false)

  const errorMessage = reactive({
    canPerformLicenceCheck: '',
    dateOfBirth: '',
    firstName: '',
    lastName: '',
    licenceNumber: '',
    licenceType: '',
    licenceVersion: '',
  })

  const canBeInternational = ref<boolean>(true)
  onMounted(() => {
    if (hasPreviousInternationalLicence()) {
      canBeInternational.value = false
      licenceType.value = 'NZ Licence'
    }
  })

  watch(hasLicence, () => {
    if (hasLicence.value) {
      emit('hasAccreditation', props.accreditation?.id)
      return
    }
    emit('doesntHaveAccreditation', props.accreditation?.id)

    if (canBeInternational.value) {
      licenceType.value = undefined
    } else {
      licenceType.value = 'NZ Licence'
    }
  })

  watchEffect(() => {
    if (props.shouldValidate) {
      validate()
    }
  })

  watch(lastName, () => manageErrorMessage(lastName.value, 'lastName'))
  watch(dateOfBirth, () => {
    manageErrorMessage(dateOfBirth.value, 'dateOfBirth')
  })
  watch(licenceNumber, () =>
    manageErrorMessage(licenceNumber.value, 'licenceNumber'),
  )
  watch(licenceVersion, () =>
    manageErrorMessage(licenceVersion.value, 'licenceVersion'),
  )
  watch(canPerformLicenceCheck, () => {
    manageConsent()
  })
  watch(licenceType, () => manageErrorMessage(licenceType.value, 'licenceType'))

  function manageErrorMessage(data: string | undefined, fieldName: string) {
    if (data && data !== '') {
      errorMessage[fieldName] = ''
      return
    }

    errorMessage[fieldName] = 'Field cannot be empty'
  }

  function manageConsent() {
    errorMessage.canPerformLicenceCheck = canPerformLicenceCheck.value
      ? ''
      : 'Must give consent'
  }

  function selectLicenceType(
    type: 'NZ Licence' | 'International Licence' | undefined,
  ) {
    licenceType.value = type
  }

  function validate(): boolean {
    if (hasLicence.value === false) {
      emit('validated')
      return false
    }

    manageErrorMessage(lastName.value, 'lastName')
    manageErrorMessage(dateOfBirth.value, 'dateOfBirth')
    manageErrorMessage(licenceNumber.value, 'licenceNumber')
    manageErrorMessage(licenceVersion.value, 'licenceVersion')
    manageErrorMessage(licenceType.value, 'licenceType')
    manageConsent()

    const isValid =
      Object.values(errorMessage).every((message) => message === '') ||
      licenceType.value === 'International Licence'

    emit('validated')
    emit('canSave', {
      canSave: isValid,
      userAccreditation: isValid ? createUserAccreditationRequestData() : null,
    })

    return isValid
  }

  function createUserAccreditationRequestData() {
    if (props.accreditation?.id === undefined) {
      throw new ReportableException('Missing accreditation data', {
        accreditation: props.accreditation,
      })
    }

    const data = {
      accreditationId: props.accreditation.id,
      accreditationName: props.accreditation.name,
      isInternationalLicence: licenceType.value === 'International Licence',
      userId: useUserStore().id,
    }

    if (licenceType.value === 'NZ Licence') {
      data['consent'] = canPerformLicenceCheck.value
      data['country'] = 'NZ'
      data['firstName'] = firstName.value
      data['lastName'] = lastName.value
      data['licenceVersion'] = licenceVersion.value
      data['licenceNumber'] = licenceNumber.value
      data['dateOfBirth'] = dayjs(dateOfBirth.value)
        .format('DD-MM-YYYY')
        .split('-')
        .reverse()
        .join('-')
    }

    return data
  }

  function hasPreviousInternationalLicence(): boolean {
    const siteEntryCheck = getSiteEntryCheck('Accreditation') as
      | SiteEntryCheckType<AccreditationCheckType>
      | undefined

    if (siteEntryCheck === undefined || !siteEntryCheck.checks) {
      return false
    }

    return siteEntryCheck.checks.some((check: AccreditationCheckType) => {
      return (
        check.name === 'New Zealand Drivers Licence' &&
        check.description === 'International Drivers Licence'
      )
    })
  }
</script>
